import { useField } from 'formik';
import { Button, ButtonColor } from '@components/v2';
import React, { useState } from 'react';
import { ColorResult, TwitterPicker } from 'react-color';
import OutsideClick from 'react-outside-click-handler';

import styles from './styles.module.scss';

const defaultColorPalette = [
  '#D50000',
  '#E67C73',
  '#F4511E',
  '#F6BF26',
  '#33B679',
  '#0B8043',
  '#039BE5',
  '#3F51B5',
  '#7986CB',
  '#8E24AA',
  '#a1a1a1',
  '#616161',
];

interface IProps {
  buttonLabel?: string;
  value: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
}

const ColorPickerInput = ({ buttonLabel = 'Item Color', value, onChange, disabled }: IProps) => {
  const [pickerVisible, setPickerVisible] = useState<boolean>(false);

  function onToggleColorPicker() {
    if (!disabled) {
      setPickerVisible((prev) => !prev);
    }
  }

  function onColorChangeComplete(color: ColorResult) {
    setPickerVisible(false);
    onChange(color.hex);
  }

  function onKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    event.key === 'Enter' && event.preventDefault();
  }

  return (
    <div className={styles.colorPicker} onKeyPress={onKeyPress}>
      <div className={styles.colorPickerControls} onClick={onToggleColorPicker}>
        <div className={styles.currentColor} style={{ background: value }} />
        <Button type="button" color={ButtonColor.TEXT_PEACOCK}>
          <span className={styles.buttonLabel}>{buttonLabel}</span>
        </Button>
      </div>
      {pickerVisible ? (
        <OutsideClick onOutsideClick={onToggleColorPicker}>
          <div className={styles.pickerWrapper}>
            <TwitterPicker
              width="315px"
              color={value}
              onChangeComplete={onColorChangeComplete}
              colors={defaultColorPalette}
            />
          </div>
        </OutsideClick>
      ) : null}
    </div>
  );
};

export default ColorPickerInput;

interface IFormikColorPickerInputProps {
  id: string;
  disabled?: boolean;
}

export const FormikColorPickerInput = ({ id, disabled }: IFormikColorPickerInputProps) => {
  const [field, meta] = useField<string>(id);

  const { touched, error } = meta;

  const { value, onChange } = field;

  function onValueChange(newVal: string) {
    onChange(id)(newVal);
  }

  return (
    <>
      <ColorPickerInput value={value} onChange={onValueChange} disabled={disabled} />
      {touched && error ? <span className={styles.error}>{error}</span> : null}
    </>
  );
};

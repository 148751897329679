import { ILeaveHRVM, IMessageVM, INoteVM, IPartialUserVM, IUserVM } from '@types';

export interface IStepListWithImages {
  title: string;
  content: string;
  imageUrl: string;
  customStep?: string;
}

export interface ITimeOffBalance {
  id: number;
  createdAt: string;
  updatedAt: string;
  lastSyncedAt: string;
  balance: number;
  employee: number;
  isUnlimited: boolean;
  balanceType: string;
}

export interface IDocument {
  id: number;
  name: string;
  userRole: EDocumentUserRole[];
  description: string;
  instructions?: string;
  documentTypeFileDownload?: string;
  documentTypeDueDate?: string;
  type: number;
  leave: number;
  fileDownload?: string;
  filePreview?: string;
  fileName?: string;
  fileUploadRequired: boolean;
  dueDate?: string;
  startDate: string | null;
  status: EDocumentStatus;
  uploadedAt?: string;
  medCertChunks?: ILeaveChunk[];
  notAcceptedReason?: EDocumentNotAcceptedReason;
  documentType: number;
  createdAt: string;
  documentId?: number;
  updatedAt: string;
}

export interface IDocumentType {
  id: number;
  name: string;
  instructions?: string;
  fileUploadRequired?: boolean;
  fileDownload?: string;
  filePreview?: string;
  description?: string;
  dueDate?: string;
  startDate?: string;
  type: number;
  leaveTypes: string[];
  parent?: number;
}
export interface IDocumentResult {
  id: number;
  awake: boolean;
  documentType: IDocumentType;
  leave: number;
  reasoning: string;
  required: boolean;
  createdAt: string;
  updatedAt: string;
  reasonsSuggested?: string[];
}

export interface IDocumentHistory {
  filePreview?: string;
  fileDownload?: string;
  fileName?: string;
  historyDate: string;
  historyId: number;
  historyUser: IUserVM;
  status: EDocumentStatus;
  uploadedAt?: string;
}

export enum EDocumentStatus {
  UPLOAD = 1,
  UPLOAD_COMPLETE = 2,
  VIEW = 3,
  NEEDS_REVIEW = 4,
  ACCEPTED = 5,
  NOT_ACCEPTED = 6,
  VIEW_COMPLETE = 7,
  LSM_UPLOAD_REQUIRED = 8,
  CANCELED = 9,
}

export enum EDocumentNotAcceptedReason {
  INCOMPLETE = 1,
  INSUFFICIENT = 2,
  DEADLINE_MISSED = 3,
  INCORRECT = 4,
}

export enum EDocumentUserRole {
  EMPLOYEE = 1,
  HR = 2,
  MANAGER = 3,
  LSM = 4,
}

export enum EDocumentType {
  EXPLANATION_OF_BENEFITS_STATE = 0,
  EXPLANATION_OF_BENEFITS_INSURANCE_STD = 1,
  LEAVE_ACCOMMODATION_FORM = 2,
  RETURN_TO_WORK_CERT = 3,
  BENEFIT_PROMISSORY_NOTE = 4,
  FMLA_DESIGNATION_NOTICE = 5,
  NON_FMLA_CAREGIVING_MED_CERT = 6,
  FMLA_CAREGIVING_MED_CERT = 7,
  NON_FMLA_MED_CERT = 8,
  FMLA_MED_CERT = 9,
  NOTICE_OF_ELIGIBILITY_AND_RIGHTS_AND_RESPONSIBILITIES = 10,
  PROOF_OF_BONDING = 11,
  OTHER = 12,
  MILITARY_ORDERS = 13,
  ADA = 14,
  PWFA = 15,
  MILITARY_LEAVE_EARNINGS_STATEMENT = 16,
  EXPLANATION_OF_BENEFITS_INSURANCE_LTD = 17,
  EXPLANATION_OF_BENEFITS_STATE_BONDING = 18,
  EXPLANATION_OF_BENEFITS_CANADA = 19,
  INSURANCE_DECISION_LETTER_STD = 20,
  INSURANCE_DECISION_LETTER_LTD = 21,
  PFML_DECISION_LETTER = 22,
  USERRA = 23,
}

export enum DOCUMENT_CATEGORY {
  MedCert = 'MedCert',
  ExplanationOfBenefits = 'ExplanationOfBenefits',
  Other = 'Other',
}

export enum EAggregateDocumentType {
  MEDCERT = -1,
}

export enum EBenefitStatus {
  PENDING = 1,
  APPROVED = 2,
}
export interface ISelectOption {
  value: string | number;
  label: string;
  icon?: string;
  iconColor?: string;
  component?: React.ReactNode;
}

export enum ELeaveChunkType {
  CONTINUOUS = 1,
  INTERMITTENT = 2,
  REDUCED_SCHEDULE = 3,
}

export interface ILeaveChunk {
  id?: number;
  durationType: ELeaveChunkType;
  startDate?: string;
  endDate?: string;
  episodeFrequencyMax?: number;
  episodeFrequencyPeriod?: number;
  episodeDurationMax?: number;
  episodeDurationPeriod?: number;
  treatmentFrequencyMax?: number;
  treatmentFrequencyPeriod?: number;
  treatmentDurationMax?: number;
  treatmentDurationPeriod?: number;
  reducedScheduleEstimate?: string;
}

export type DocumentTypeStartDueDateRecord = {
  djangoModel: string;
  property: string;
  plusMinus?: 'plus' | 'minus';
  number?: number;
};

//Activity Feed: LEX-338
export enum EActionVerb {
  APPROVED = 'approved',
  ARCHIVED = 'archived',
  CREATED = 'created',
  DELETED = 'deleted',
  PUBLISHED = 'published',
  SENT = 'sent',
  SUBMITTED = 'submitted',
  UPDATED = 'updated',
  UNARCHIVED = 'unarchived',
  REJECTED = 'rejected',
  REQUESTED = 'requested',
}

export enum EActionObjectType {
  MESSAGE = 'Message',
  LEAVE = 'Leave',
  DOCUMENT = 'Document',
  NOTE = 'Note',
  SCHEDULE_DAY = 'ScheduleDay',
  PAYCALC = 'Paycalc',
  PROFILE = 'Profile',
}

export enum EActorType {
  USER = 'User',
  TILT = 'Tilt',
}

export enum ETargetType {
  LEAVE = 'Leave',
  USER = 'User',
}

export interface IActor {
  email: string;
  employeeId: string;
  firstName: string;
  id: number;
  isArchived: boolean;
  lastName: string;
  loggedIn: boolean;
  personalEmail: string;
  phone: string;
  remoteEmployeeId: string;
  sftpMethod: string;
}
export type ActionObject =
  | IMessageVM
  | IDocument
  | INoteVM
  | (Partial<ILeaveHRVM> & {
      homeState?: string;
      workingCity?: string;
      fmlaEligibleAt?: boolean;
      isIntegrated?: boolean;
      historicalProfileId?: number;
    });
export interface IAction {
  actionObject: ActionObject;
  actionObjectType: EActionObjectType;
  actor: IActor;
  actorType: EActorType;
  description?: string;
  id: number;
  notes?: string[];
  target: Partial<ILeaveHRVM> & {
    homeState: string;
    workingCity: string;
    fmlaEligibleAt: boolean;
    isIntegrated: boolean;
    historicalProfileId: number;
  };
  targetType: ETargetType;
  timestamp: string;
  verb: EActionVerb;
  impersonator?: IPartialUserVM;
}

import COMMON_REGEX from '../../constants/common-regex';
import { trim } from 'lodash';

export const fieldRequired = (value) => {
  const isEmptyArray = Array.isArray(value) && !value.length;

  if (value == null || trim(value) === '' || isEmptyArray) {
    return 'This field is required!';
  }
  return undefined;
};

export const validEmail = (value) => {
  if (
    value &&
    !String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  ) {
    return 'Email is not valid';
  }
  return undefined;
};

export const validDate = (value) => {
  const msg = 'Date is not valid';
  const dateParts = value.split('-');
  const year = Number.parseInt(dateParts[0], 10);
  const month = Number.parseInt(dateParts[1], 10);
  const day = Number.parseInt(dateParts[2], 10);
  // Algorithm: https://en.wikipedia.org/wiki/Leap_year
  const isLeapYear = (y) => (!(y % 4) && y % 100) || !(y % 400);
  // Don't assume leap year for Feb
  const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (isLeapYear(year)) {
    monthDays[1] = 29;
  }

  if (!value) {
    return undefined;
  }

  const validValues = !(Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(year));
  const validMonth = month > 0 && month <= 12;
  const validDay = day > 0 && day <= monthDays[month - 1];

  if (!validValues || !validMonth || !validDay) {
    return msg;
  }
  return undefined;
};

export const requiredValidDate = (value) => {
  if (!value) {
    return 'This field is required!';
  }

  return validDate(value) ?? fieldRequired(value);
};

export const validPassword = (value) => {
  if (value && !value.match(COMMON_REGEX.password)) {
    return 'Password is not valid';
  }
  return undefined;
};

const regExp = /^[A-Za-z0-9](?:[A-Za-z0-9]{0,61}[A-Za-z0-9])?$/;

export function isSubdomainValid(subdomain) {
  return regExp.test(subdomain);
}

export const validSubdomain = (value) => {
  if (value === 'admin' || value === 'dev-admin' || value === 'secure' || value === 'login') {
    return false;
  }

  return isSubdomainValid(value);
};

export const validUrl = (value) => {
  if (!value) {
    return undefined;
  }
  if (isUrl(value)) {
    return undefined;
  }
  return 'URL is not valid';
};

export function isUrl(string) {
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string');
  }

  string = string.trim();
  if (string.includes(' ')) {
    return false;
  }

  try {
    const url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:' || isValidPath(url.pathname);
  } catch {
    return false;
  }
}

export const isValidPath = (path) => {
  // Starts with '/',
  // contains only letters, numbers, hyphens, underscores, periods, and slashes
  // no double slashes
  const pattern = /^(?!.*[\s:])\/(?:[^/]+(?:\/|$))*$/;
  return pattern.test(path);
};

export const validOrder = (value) => {
  if (!value) {
    return undefined;
  }
  if (parseInt(value, 10) >= 0) {
    return undefined;
  }
  return 'Order must be a number bigger than 0';
};

const youtubeRegExp =
  /(?:(?:https?:\/\/)(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/))([a-zA-Z0-9_-]{11})/;
const vimeoRegExp = /.(vimeo)\.com\/(\d+)/;
export const isYoutube = (value) => youtubeRegExp.test(value);
export const isVimeo = (value) => vimeoRegExp.test(value);

export const validVideoUrl = (value) => {
  if (!value) {
    return undefined;
  }
  if (isYoutube(value) || isVimeo(value)) {
    return undefined;
  }
  return 'Only YouTube and Vimeo links are supported.';
};

export const validPhone = (value) => {
  const msg = 'Phone is not valid';

  if (!value) {
    return undefined;
  }

  if (value && !value.match(COMMON_REGEX.phone)) {
    return msg;
  }
  return undefined;
};

export const validZipCode = (value) => {
  if (!value) {
    return undefined;
  }

  if (value && !value.match(COMMON_REGEX.zipCode)) {
    return 'Zip code is not valid';
  }

  return undefined;
};

export const validSSN = (value) => {
  if (!value) {
    return undefined;
  }

  if (value && !value.match(COMMON_REGEX.ssn)) {
    return 'SSN is not valid';
  }

  return undefined;
};

export default {
  fieldRequired,
  validEmail,
  validDate,
  validSubdomain,
  validPassword,
  validUrl,
  validZipCode,
  validSSN,
};

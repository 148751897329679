/* eslint-disable react-hooks/rules-of-hooks */
import useApi from '@api/transportLayer';
import { FormikColorPickerInput } from '@components/ColorPickerInput';
import FeatherIcon from '@components/FeatherIcon';
import Checkbox from '@components/Forms/Checkbox';
import FormError from '@components/Forms/Error/FormError';
import Input from '@components/Forms/Input';
import RadioButtons from '@components/Forms/RadioButtons';
import SearchSelectField, { ISearchSelectOption } from '@components/Forms/SearchSelectField';
import Textarea from '@components/Forms/Textarea';
import Modal from '@components/Modal';
import { ScheduleProcessor } from '@components/PlanCalendar/ScheduleProcessor';
import Tooltip from '@components/Tooltip';
import { Button, ButtonColor } from '@components/v2';
import { Banner } from '@components/v2';
import { EBannerStatus } from '@components/v2/Banner/Banner';
import colors from '@constants/colors';
import { EAggregateDocumentType, EDocumentType } from '@constants/types';
import { ConditionalWrapper } from '@screens/Hr/Settings/components/ConditionalWrapper';
import {
  EBenefitTrackingMethod,
  EEarningTypes,
  EPaycalcItemLength,
  EPayPeriodLength,
  EPermissionValue,
  EProrationMethod,
  ERoundingMethod,
  IPayCalcItemTemplateResultVM,
  IPayCalcItemTemplateVM,
  IPayCalcItemVM,
  IPermissions,
  IPlanVM,
} from '@types';
import { fieldRequired } from '@utils/validators';
import { BALANCE_TYPES } from '@constants/index';
import { useUUIDs } from 'contexts/uuid-context';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { calculateHoursAndMinutes, formatBalance } from 'utils';
import * as Yup from 'yup';
import cx from 'classnames';

import styles from './modalStyles.module.scss';

const scheduleProcessor: ScheduleProcessor = new ScheduleProcessor();

const visibility = {
  proration: ({ payPeriodLength }) =>
    payPeriodLength === EPaycalcItemLength.PAY_PERIOD || payPeriodLength === EPaycalcItemLength.WEEKLY,
  payPercentage: ({ usepayPercentage }) => usepayPercentage === '1',
  rounding: ({ usepayPercentage }) => usepayPercentage === '1',
};
const commonInitialValues = {
  title: '',
  payPerPeriod: 0,
  maxPayPerPeriod: 0,
  payPercentage: 0,
  prorationMethod: EProrationMethod.CALENDAR_DAYS,
  roundingMethod: ERoundingMethod.NONE,
  payPeriodLength: '',
  earningType: '',
  topsUpAmount: false,
  isPaidByCompany: false,
  paysOnWorkedDays: true,
  paysOnLeaveDays: true,
  maxDuration: 0,
  order: 5,
  // Let formik handle this value for us
  usepayPercentage: '0',
  waitingPeriod: 0,
  durationInWeeks: 1,
  color: '#dddddd',
  help: '',
  requiresApplication: false,
  documentClass: null,
  approvalType: '0',
};
const formInitialValuesItem = {
  ...commonInitialValues,
  from: '',
  to: '',
  notes: '',
};

const formInitialValuesTemplate = {
  ...commonInitialValues,
  description: '',
  externalTitle: '',
  company: null,
  insuranceCompany: null,
  areBonusesIncluded: false,
  effectiveStartDate: null,
  effectiveEndDate: null,
};

const flattenItem = (
  p: IPayCalcItemTemplateVM | Record<string, unknown>,
  isTemplate: boolean,
): IPayCalcItemVM | IPayCalcItemTemplateVM => {
  const v: any = {
    ...p,
    maxPayPerPeriod: p.maxPayPerPeriod || 0,
    order: p.order || 0,
    maxDuration: p.maxDuration || 0,
    waitingPeriod: p.waitingPeriod || 0,
    help: p.help || '',
    description: p.description || '',
  };
  if (visibility.payPercentage(v)) {
    // Set payPerPeriod to 0 when use PayPerPeriod Percentage
    v.payPerPeriod = 0;
  } else {
    // Set payPercentage to 0 and roundingMethod to NONE when use fixed amount
    v.payPercentage = 0;
    v.roundingMethod = ERoundingMethod.NONE;
  }
  delete v.usepayPercentage;
  delete v.durationInWeeks;
  delete v.durationInDays;
  delete v.__typename;
  if (!isTemplate) {
    // delete template fields
    delete v.color;
    delete v.help;
    delete v.description;
    delete v.isPaidByCompany;
    delete v.company;
  }
  return v;
};

const BasePayCalcItemSchema = {
  title: Yup.string().required('Required').max(63, 'Title cannot have more than 63 characters'),
  payPerPeriod: Yup.number().min(0, 'Must be a positive number'),
  maxPayPerPeriod: Yup.number().min(0, 'Must be a positive number'),
  payPercentage: Yup.number().min(0, 'Must be a positive number').max(100),
  earningType: Yup.string().required('Required'),
  payPeriodLength: Yup.string().required('Required'),
  prorationMethod: Yup.string().required('Required'),
  roundingMethod: Yup.string().required('Required'),
  topsUpAmount: Yup.boolean().required('Required'),
  paysOnWorkedDays: Yup.boolean().required('Required'),
  paysOnLeaveDays: Yup.boolean().required('Required'),
  order: Yup.number().min(0, 'Must be a positive number').max(20),
  maxDuration: Yup.number().min(0, 'Must be a positive number').max(1000),
  waitingPeriod: Yup.number().min(0, 'Must be a positive number'),
};

const PayCalcItemTemplateSchema = Yup.object().shape({
  ...BasePayCalcItemSchema,
  color: Yup.string().max(9, 'Color format should be #XXXXXX[YY]'),
  description: Yup.string().max(1024, 'Description cannot have more than 255 characters'),
  help: Yup.string().max(1024, 'Help cannot have more than 255 characters'),
  isPaidByCompany: Yup.boolean().required('Required'),
  externalTitle: Yup.string()
    .required(
      'External Title is a Required Field. If one is not provided, it will be defaulted to the provided Item Title',
    )
    .min(1, 'External Title is a Required Field')
    .max(63, 'External Title cannot have more than 63 characters'),
  insuranceCompany: Yup.string().nullable().max(63, 'Insurance Provider cannot have more than 63 characters'),
  areBonusesIncluded: Yup.boolean().nullable(),
  effectiveStartDate: Yup.date().nullable(),
  effectiveEndDate: Yup.date()
    .nullable()
    .min(Yup.ref('effectiveStartDate'), 'Effective end date cannot be earlier than effective start date'),
});

const PayCalcItemSchema = Yup.object().shape({
  ...BasePayCalcItemSchema,
  from: Yup.date().required('Required'),
  to: Yup.date().required('Required'),
  notes: Yup.string().max(255, 'Notes cannot have more than 255 characters'),
});

export const prorationMethodMap = new Map<EProrationMethod, string>([
  [EProrationMethod.CALENDAR_DAYS, 'Calendar Days'],
  [EProrationMethod.WORKING_DAYS, 'Working Days'],
]);

const prorationMethodOptions = Array.from(prorationMethodMap).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const roundingMethodMap = new Map<ERoundingMethod, string>([
  [ERoundingMethod.NONE, 'None'],
  [ERoundingMethod.ALWAYS_ROUND_UP, 'Always Round Up'],
  [ERoundingMethod.ROUND_TO_NEAREST_DOLLAR, 'Round to Nearest Dollar'],
]);

const roundingMethodOptions = Array.from(roundingMethodMap).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const itemPeriodLengthMap = new Map<EPaycalcItemLength, string>([
  [EPaycalcItemLength.PAY_PERIOD, 'Period'],
  [EPaycalcItemLength.MONTHLY, 'Monthly'],
  [EPaycalcItemLength.WEEKLY, 'Weekly'],
  [EPaycalcItemLength.CALENDAR_DAY, 'Calendar Day'],
  [EPaycalcItemLength.WORKING_DAY, 'Working Day'],
  [EPaycalcItemLength.HOURLY, 'Hourly'],
]);

export const earningTypeMap = new Map<EEarningTypes, string>([
  [EEarningTypes.COMPANY_REGULAR_WAGES, 'Company Paid Regular Wages'],
  [EEarningTypes.STD_PAID_BENEFIT, 'STD Paid Benefit'],
  [EEarningTypes.LTD_PAID_BENEFIT, 'LTD Paid Benefit'],
  [EEarningTypes.STATE_PAID_BENEFIT, 'State Paid Benefit'],
  [EEarningTypes.STATE_UNPAID_BENEFIT, 'State Unpaid Benefit'],
  [EEarningTypes.FEDERAL_GOVERNMENT_PAID_BENEFIT, 'Federal Government Paid Benefit'],
  [EEarningTypes.FEDERAL_GOVERNMENT_UNPAID_BENEFIT, 'Federal Government Unpaid Benefit'],
  [EEarningTypes.TILT_ADMINISTRATIVE_UNPAID, 'Tilt Administrative Unpaid'],
  [EEarningTypes.COMPANY_PAID_PARENTAL_LEAVE, 'Company Paid Parental Leave'],
  [EEarningTypes.COMPANY_PAID_CAREGIVER_LEAVE, 'Company Paid Caregiving Leave'],
  [EEarningTypes.COMPANY_PAID_MEDICAL_LEAVE, 'Company Paid Medical Leave'],
  [EEarningTypes.COMPANY_PAID_MILITARY_LEAVE, 'Company Paid Military Leave'],
  [EEarningTypes.COMPANY_PAID_EXTRAS, 'Company Paid Extras'],
  [EEarningTypes.COMPANY_PAID_LEAVE_OTHER, 'Company Paid Leave Other'],
  [EEarningTypes.COMPANY_UNPAID_LEAVE, 'Company Unpaid Leave'],
  [EEarningTypes.COMPANY_HOLIDAY_PAY, 'Company Holiday Pay'],
  [EEarningTypes.COMPANY_PTO_PAY, 'Company PTO Pay'],
  [EEarningTypes.COMPANY_SICK_PAY, 'Company Sick Pay'],
  [EEarningTypes.MILITARY_PAY, 'Military Pay'],
  [EEarningTypes.CANADIAN_PAID_BENEFIT, 'Canadian Paid Benefit'],
]);

export const benefitTrackingMethodMap = new Map<EBenefitTrackingMethod, string>([
  [EBenefitTrackingMethod.CALENDAR_YEAR, 'Calendar Year'],
  [EBenefitTrackingMethod.FIXED_YEAR, 'Fixed Year'],
  [EBenefitTrackingMethod.ROLLING_BACK, 'Rolling Back'],
  [EBenefitTrackingMethod.ROLLING_FORWARD, 'Rolling Forward'],
  [EBenefitTrackingMethod.EVENT_BASED, 'Event Based'],
]);

export const paidRipplingItems = [
  EEarningTypes.COMPANY_PAID_MEDICAL_LEAVE,
  EEarningTypes.COMPANY_PAID_PARENTAL_LEAVE,
  EEarningTypes.COMPANY_PAID_CAREGIVER_LEAVE,
  EEarningTypes.COMPANY_PAID_MILITARY_LEAVE,
  EEarningTypes.COMPANY_PAID_LEAVE_OTHER,
  EEarningTypes.COMPANY_UNPAID_LEAVE,
];

export const approvalDocumentClassMap = new Map<EDocumentType | EAggregateDocumentType, string>([
  [EDocumentType.EXPLANATION_OF_BENEFITS_STATE, `Explanation of Benefits (State)`],
  [EDocumentType.EXPLANATION_OF_BENEFITS_INSURANCE_STD, `Explanation of Benefits from Short Term Disability Providers`],
  [EDocumentType.PROOF_OF_BONDING, `Proof of Bonding`],
  [EAggregateDocumentType.MEDCERT, `Medical Certification`],
  [EDocumentType.EXPLANATION_OF_BENEFITS_INSURANCE_LTD, `Explanation of Benefits from Long Term Disability Providers`],
  [EDocumentType.EXPLANATION_OF_BENEFITS_STATE_BONDING, `Explanation of Benefits (State Bonding)`],
  [EDocumentType.EXPLANATION_OF_BENEFITS_CANADA, `Explanation of Benefits (Canada)`],
]);

const itemPeriodLengthOptions = Array.from(itemPeriodLengthMap, ([key, value]) => ({
  value: key,
  name: value,
}));

const earningTypeOptions = Array.from(earningTypeMap, ([key, value]) => ({
  value: key,
  name: value,
}));

const benefitTrackingOptions = Array.from(benefitTrackingMethodMap, ([key, value]) => ({
  value: key,
  name: value,
}));

const documentClassOptions = Array.from(approvalDocumentClassMap, ([key, value]) => ({
  value: key,
  name: value,
}));

// hours, days, weeks, months
const maxDurationUnitOptions = [
  { value: 'HOUR', name: 'Hours' },
  { value: 'DAY', name: 'Days' },
  { value: 'WEEK', name: 'Weeks' },
  // { value: 'MONTH', name: 'Months' }, // not supported
];

export const roundAmount = (amount, rounding_method, shouldRound) => {
  if (shouldRound) {
    switch (rounding_method) {
      case 'ALWAYS_ROUND_UP':
        return Math.ceil(amount).toFixed(2);
      case 'ROUND_TO_NEAREST_DOLLAR':
        return Math.round(amount).toFixed(2);
      default:
        return amount.toFixed(2);
    }
  } else {
    return amount.toFixed(2);
  }
};

// TODO SHARE-830: cleanup this component and how it uses paycalc item data

interface PayCalcItemModalProps {
  open: boolean;
  payCalcItem?: Partial<IPayCalcItemVM | IPayCalcItemTemplateVM>;
  onClose: () => void;
  onSubmit: (payCalcItem: IPayCalcItemVM | IPayCalcItemTemplateVM) => Promise<void>;
  onRemoveEvent?: (id?: any) => Promise<void>;
  minDate?: string;
  plan?: IPlanVM;
  maxDate?: string;
  payCalcPayPeriodLength?: EPayPeriodLength;
  payCalcPayPerPeriod?: number;
  isTemplate?: boolean;
  payCalcItemTemplates?: IPayCalcItemTemplateVM[];
  computedData?: boolean;
  updatingBenefitResult?: boolean;
  insuranceProviders?: ISearchSelectOption[];
  multiplePayCalcsHeader?: string;
  preventCloseOnSubmit?: boolean;
  permissions?: IPermissions;
}

const PayCalcItemModal = ({
  open,
  onClose,
  plan,
  onSubmit,
  onRemoveEvent,
  payCalcItem,
  minDate,
  maxDate,
  payCalcPayPeriodLength,
  payCalcPayPerPeriod,
  isTemplate,
  payCalcItemTemplates,
  computedData,
  updatingBenefitResult,
  insuranceProviders,
  multiplePayCalcsHeader = '',
  preventCloseOnSubmit,
  permissions,
}: PayCalcItemModalProps) => {
  const {
    state: { uuids, ripplingTopUpUUIDs: RIPPLING_TOP_UP_TEMPLATES, ripplingPtoUUIDs: RIPPLING_PTO_PAYCALC_TEMPLATES },
  } = useUUIDs();

  const [submitError, setSubmitError] = useState('');
  const companyId = plan?.companyId || null;
  const [ripplingTopUpSelected, setRipplingTopUpSelected] = useState(
    (payCalcItem as IPayCalcItemVM)?.template?.uuid
      ? RIPPLING_TOP_UP_TEMPLATES.includes((payCalcItem as IPayCalcItemVM)?.template?.uuid)
      : false,
  );
  const [ripplingHolidaySelected, setRipplingHolidaySelected] = useState(
    (payCalcItem as IPayCalcItemVM)?.template?.uuid
      ? uuids?.RIPPLING_HOLIDAY_TEMPLATE_UUID === (payCalcItem as IPayCalcItemVM)?.template?.uuid
      : false,
  );
  const { data: company } = useApi.Admin.Company.getOne({ companyId }, { enabled: !!companyId });

  const { data: flags } = useApi.Flags.getCompanyFlags(
    { companyId, enableRipplingPtoIngestion: '', enableRipplingPtoBalances: '', enableRipplingTopUpFlexibility: '' },
    { enabled: !!companyId },
  );

  const { data: profileData } = useApi.Admin.Leave.HistoricalProfile.getOne(
    { leaveId: plan?.leave?.id },
    { enabled: !!plan?.leave?.id },
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      delete values.company;
      setSubmitError('');

      let formData = flattenItem(values, isTemplate);

      if (!isTemplate) {
        const payCalcData = formData as IPayCalcItemVM;

        if (payCalcData.automated) {
          const initialData = flattenItem(initialValues, isTemplate) as IPayCalcItemVM;
          payCalcData.automated = isEqual(initialData, payCalcData);
          formData = payCalcData;
        }
      }

      formData.needsManualApproval = values.approvalType === '1' ? true : false;

      await onSubmit(formData);
      setSubmitting(false);
      if (!preventCloseOnSubmit) {
        onClose();
      }
    } catch (error) {
      setSubmitError(error || 'Internal error');
      setSubmitting(false);
    }
  };

  const [isRemove, setIsRemove] = useState(false);
  const onRemove = async (setSubmitting: (arg: boolean) => void) => {
    setIsRemove(true);
    setSubmitting(true);
    try {
      setSubmitError('');
      await onRemoveEvent(payCalcItem?.id);
      onClose();
    } catch (error) {
      setSubmitError(error?.message || 'Internal error');
    } finally {
      setIsRemove(false);
      setSubmitting(false);
    }
  };

  const isEdit =
    !!(payCalcItem as IPayCalcItemVM)?.paycalcId ||
    (isTemplate && !!(payCalcItem as IPayCalcItemTemplateVM)?.id) ||
    updatingBenefitResult;

  const thing = isTemplate ? 'Item Template' : 'Item';
  const buttonLabel = isEdit ? 'Update' : 'Add';

  const formInitialValues = isTemplate ? formInitialValuesTemplate : formInitialValuesItem;
  const schema = isTemplate ? PayCalcItemTemplateSchema : PayCalcItemSchema;

  formInitialValues.usepayPercentage = Number(payCalcItem?.payPercentage) > 0 ? '1' : '0';

  const template = (payCalcItem as IPayCalcItemVM).template;

  const approvalOptions = [
    { value: '0', label: 'None' },
    { value: '1', label: 'Manual Approval' },
    { value: '2', label: 'Document Approval' },
  ];

  if (template) {
    const { color, help, isPaidByCompany } = template;
    formInitialValues.color = color;
    formInitialValues.help = help;
    formInitialValues.isPaidByCompany = isPaidByCompany;
  }

  const isRipplingPTOItem =
    company?.isRipplingIntegrated &&
    !isTemplate &&
    isEdit &&
    flags?.enableRipplingPtoIngestion &&
    company?.hasPayPush &&
    RIPPLING_PTO_PAYCALC_TEMPLATES.includes(template?.uuid);

  const modalTitle = () => {
    if (isRipplingPTOItem) {
      return (
        <span className={styles.ripplingModalTitle}>
          <h2>Edit Pay Calc Item</h2>
          <Tooltip
            content="Some items are read-only because this Paycalc Item is connected through Rippling."
            direction="top"
            dark
            hoverShowDelayMs={300}
            size="large"
          >
            <div className={styles.icon}>
              <FeatherIcon name="Lock" size={24} />
            </div>
          </Tooltip>
        </span>
      );
    }

    const editModalHeader = multiplePayCalcsHeader
      ? `Edit Pay Calc ${thing} ${multiplePayCalcsHeader}`
      : `Edit Pay Calc ${thing}`;

    if (isEdit) {
      return <h2>{editModalHeader}</h2>;
    }

    return (
      <h2>
        New Pay Calc {thing} {multiplePayCalcsHeader}
      </h2>
    );
  };

  const filterItems = (items, values) => {
    if (
      isEdit &&
      values.template &&
      (RIPPLING_PTO_PAYCALC_TEMPLATES.includes(values.template.uuid) ||
        uuids?.RIPPLING_HOLIDAY_TEMPLATE_UUID === values.template.uuid)
    )
      return items;

    if (!company.hasPayPush) {
      return items.filter(
        (item) =>
          item.uuid !== uuids?.RIPPLING_HOLIDAY_TEMPLATE_UUID &&
          !RIPPLING_TOP_UP_TEMPLATES.includes(item.uuid) &&
          !RIPPLING_PTO_PAYCALC_TEMPLATES.includes(item.uuid),
      );
    }
    if (!flags?.enableRipplingPtoBalances) {
      items = items.filter((item) => !RIPPLING_TOP_UP_TEMPLATES.includes(item.uuid));
    } else if (flags?.enableRipplingPtoBalances) {
      items = items.filter(
        (item) =>
          ![EEarningTypes.COMPANY_PTO_PAY, EEarningTypes.COMPANY_SICK_PAY].includes(item.earningType) ||
          RIPPLING_TOP_UP_TEMPLATES.includes(item.uuid),
      );
    } else if (flags?.enableRipplingPtoIngestion) {
      items = items.filter(
        (item) =>
          ![EEarningTypes.COMPANY_PTO_PAY, EEarningTypes.COMPANY_SICK_PAY].includes(item.earningType) ||
          item.topsUpAmount,
      );
    }
    return items;
  };

  const getBanner = (templateUUID?: string, earningType?: EEarningTypes) => {
    const generateBannerBodyItems = (item) => {
      const bodyItems = [];
      if (!_.isNil(item.payPerPeriod) && item.payPerPeriod != 0) {
        // NOTE: We are including this isNil check alongside a loose equality check on 0,
        // to handle cases such as string representations of 0 that we encounter in scenarios
        // where we want to consider there being no calculated amount
        bodyItems.push('Benefit Amount');
      }
      if (item.fromField || item.toField) {
        bodyItems.push('Benefit Duration');
      }
      return bodyItems;
    };

    const bodyItems = generateBannerBodyItems(payCalcItem);

    if (computedData && !!bodyItems?.length) {
      const bannerTitle = !updatingBenefitResult
        ? "We've automatically calculated the following, please review before adding."
        : "We've automatically calculated the following updates, please review before adding.";

      const bannerBody = (
        <>
          <ul>
            {bodyItems.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </>
      );
      const bannerStatus = !updatingBenefitResult ? EBannerStatus.SUCCESS : EBannerStatus.INFO;
      return <Banner title={bannerTitle} body={bannerBody} status={bannerStatus} />;
    } else if (isRipplingPTOItem) {
      const bannerTitle = "We've automatically created your draft pay calculation.";
      const bannerBody = <>This item was created because the employee logged time in Rippling.</>;
      return <Banner title={bannerTitle} body={bannerBody} status={EBannerStatus.INFO} />;
    } else if (ripplingTopUpSelected) {
      const action = isEdit ? 'Editing' : 'Adding';

      let balanceType = null;
      if (templateUUID === uuids?.RIPPLING_VACATION_TOP_UP_TEMPLATE_UUID) {
        balanceType = BALANCE_TYPES.VACATION;
      } else if (templateUUID === uuids?.RIPPLING_PERSONAL_TIME_TOP_UP_TEMPLATE_UUID) {
        balanceType = BALANCE_TYPES.PERSONAL_DAYS;
      } else if (templateUUID === uuids?.RIPPLING_SICK_LEAVE_TOP_UP_TEMPLATE_UUID) {
        balanceType = BALANCE_TYPES.SICK;
      }

      const bannerTitle = `${action} this Pay Calc Item will push data directly to Rippling`;
      const formattedBalance = formatBalance(balanceType, profileData?.timeOffBalance);
      let decimalTime = '';
      if (formattedBalance !== 'Not available' && formattedBalance !== 'Unlimited') {
        const balanceItem = profileData?.timeOffBalance?.find((item) => item.balanceType === balanceType);
        const { hours, minutes } = calculateHoursAndMinutes(balanceItem?.balance);
        decimalTime = ` | ${hours}.${(minutes / 60).toFixed(2).split('.')[1]} hours`;
      }
      const bannerBody = (
        <div>
          Any changes you make will automatically deduct from Rippling PTO balances. For the entire pay calc, you should
          never use more PTO than the employee’s available balance. Enter the balance you would like to use in the{' '}
          <b>Max Duration</b> field (in hours).
          <div className={styles.balance}>
            Available balance: {formattedBalance}
            {decimalTime}
          </div>
        </div>
      );

      return <Banner title={bannerTitle} body={bannerBody} status={EBannerStatus.INFO_WARN} />;
    } else if (ripplingHolidaySelected) {
      const bannerTitle = 'Adding this Pay Calc Item will push data directly to Rippling.';
      const bannerBody = (
        <>
          Only use this pay calc item if the employee is on leave during a holiday and should be paid for the holiday.
          If the employee should not be paid for the holiday, please do not use this item.
        </>
      );
      return <Banner title={bannerTitle} body={bannerBody} status={EBannerStatus.WARN} />;
    } else if (company?.hasPayPush && earningType == EEarningTypes.COMPANY_HOLIDAY_PAY) {
      const bannerTitle = 'This Pay Calc Item will NOT push data to Rippling.';
      const bannerBody = (
        <>
          Only use this pay calc item if the employee is NOT on leave during the holiday. If they are on leave during
          the holiday, please use the Rippling Holiday item.
        </>
      );
      return <Banner title={bannerTitle} body={bannerBody} status={EBannerStatus.WARN} />;
    } else if (company?.hasPayPush && paidRipplingItems.includes(earningType)) {
      const action = isEdit ? 'Editing' : 'Adding';
      const bannerBody = `${action} this Pay Calc Item will push data directly to Rippling`;

      return <Banner body={bannerBody} status={EBannerStatus.WARN} />;
    }
    return null;
  };

  const setApprovalButton = (values) => {
    if (
      values.approvalType === '2' ||
      !isNaN(parseInt(values.documentClass)) ||
      !isNaN(parseInt(values?.template?.documentClass))
    ) {
      values.approvalType = '2';
    } else if (values.needsManualApproval) {
      values.approvalType = '1';
      values.documentClass = null;
    } else {
      values.approvalType = '0';
      values.documentClass = null;
    }
  };

  const initialValues = { ...formInitialValues, ...payCalcItem };
  setApprovalButton(initialValues);

  // Only guard against permissions if we are in a template
  let canChangePayCalcItemTemplates = true;
  let canDeletePayCalcItemTemplates = true;
  if (isTemplate) {
    canChangePayCalcItemTemplates = permissions?.payCalcItemTemplates?.includes(EPermissionValue.CHANGE) ?? false;
    canDeletePayCalcItemTemplates = permissions?.payCalcItemTemplates?.includes(EPermissionValue.DELETE) ?? false;
  }

  return (
    <Modal open={open} className={styles.modal} onClose={onClose}>
      <Formik
        validateOnBlur
        validateOnMount
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setValues, setSubmitting, setFieldValue, touched }) => {
          const {
            payPeriodLength,
            from,
            to,
            payPerPeriod,
            maxPayPerPeriod,
            payPercentage,
            durationInDays,
            durationInWeeks,
            paycalcItemTemplateId,
            title,
            externalTitle,
            documentClass,
            needsManualApproval,
          } = values;
          const handleExternalTitleCopyOnBlur = () => {
            if (title && !externalTitle) {
              setFieldValue('externalTitle', title);
            }
          };

          useEffect(() => {
            if (isTemplate && title && !touched.externalTitle && title.slice(0, title.length - 1) === externalTitle) {
              setFieldValue('externalTitle', title);
            }
          }, [externalTitle, setFieldValue, title, touched.externalTitle]);

          let totalAmount = 0;
          const handleChangeValues = useCallback(
            (newValues, shouldValidate = false) => {
              const areTheyEqual = isEqual(values, newValues);
              return areTheyEqual ? null : setValues(newValues, shouldValidate);
            },
            [setValues, values],
          );
          const onSelectTemplate = useCallback(
            (value?: number, resetValues = true) => {
              if (value !== 0) {
                const template: IPayCalcItemTemplateResultVM = { ...payCalcItemTemplates?.find((t) => t.id === value) };
                template.paycalcItemTemplateId = template.id;
                delete template.id;
                if (template.externalTitle) {
                  template.title = template.externalTitle;
                }
                setApprovalButton(template);
                if (resetValues) {
                  setRipplingTopUpSelected(RIPPLING_TOP_UP_TEMPLATES.includes(template.uuid));
                  setRipplingHolidaySelected(uuids?.RIPPLING_HOLIDAY_TEMPLATE_UUID === template.uuid);
                  handleChangeValues({
                    ...values,
                    ...template,
                    usepayPercentage: Number(template.payPercentage) ? '1' : '0',
                  });
                }
              } else if (resetValues) {
                setRipplingTopUpSelected(false);
                setRipplingHolidaySelected(false);
                handleChangeValues({ ...values, paycalcItemTemplateId: undefined }, false);
              }
            },
            [handleChangeValues, values],
          );

          if (!isTemplate) {
            if (computedData === true && payCalcItem?.payPerPeriod !== undefined && payCalcItem.payPerPeriod !== null) {
              handleChangeValues({ ...values });
            }
            // TODO linting error for useEffect in callback here
            useEffect(() => {
              const endMomentDate = moment(from)
                .add(durationInWeeks, 'w')
                .add(durationInDays, 'day')
                .subtract(1, 'day');
              handleChangeValues({ ...values, to: endMomentDate.format(moment.HTML5_FMT.DATE) }, false);
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [durationInWeeks, durationInDays, from]);

            useEffect(() => {
              const daysBetweenDates = moment(to).add(1, 'day').diff(from, 'day');
              if (daysBetweenDates && daysBetweenDates >= 0) {
                handleChangeValues(
                  {
                    ...values,
                    durationInWeeks: Math.floor(daysBetweenDates / 7),
                    durationInDays: daysBetweenDates % 7,
                  },
                  false,
                );
              }
              if (paycalcItemTemplateId) {
                onSelectTemplate(paycalcItemTemplateId, false);
              }
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [to]);

            let baseAmount;
            if (visibility.payPercentage(values)) {
              baseAmount = scheduleProcessor.transformPayPerPeriod({
                payPerPeriod: payCalcPayPerPeriod,
                payPercentage,
                fromLength: payCalcPayPeriodLength,
                toLength: payPeriodLength,
              });
            } else {
              baseAmount = payPerPeriod;
            }
            totalAmount = Math.min(Number(baseAmount), Number(maxPayPerPeriod) || 10000000);
          }

          let payCalcItemTemplateOptions = [];
          if (!isTemplate && payCalcItemTemplates) {
            payCalcItemTemplateOptions = filterItems(payCalcItemTemplates, values)
              .map((value) => ({
                value: value.id,
                name: value.title,
              }))
              .sort((a, b) => {
                const nameCompare = a.name.localeCompare(b.name);
                if (nameCompare === 0) {
                  return a.value - b.value;
                }
                return nameCompare;
              });
            payCalcItemTemplateOptions.unshift({ value: 0, name: ' ' });
          }

          const filteredEarningTypeOptions =
            flags?.enableRipplingPtoIngestion && company?.hasPayPush
              ? earningTypeOptions?.filter(
                  (item) =>
                    item.value !== EEarningTypes.COMPANY_PTO_PAY && item.value !== EEarningTypes.COMPANY_SICK_PAY,
                )
              : earningTypeOptions;
          return (
            <Form>
              <div className={styles.content}>
                {modalTitle()}
                {getBanner(values?.uuid || values?.template?.uuid, values?.earningType)}
                {!isTemplate && (
                  <div className={styles.formRow}>
                    <div className={styles.col}>
                      <SearchSelectField
                        id="paycalcItemTemplateId"
                        options={payCalcItemTemplateOptions}
                        onChange={(id: string) => onSelectTemplate(parseInt(id))}
                        getOptions={(q) =>
                          payCalcItemTemplateOptions.filter((e) => e.name.toLowerCase().includes(q.toLowerCase()))
                        }
                        disabled={isRipplingPTOItem}
                        multiple={false}
                        allowSearch={true}
                        autoComplete="on"
                        placeholder="Select a template"
                        label="Select a template"
                        className={styles.payFrequency}
                      />
                    </div>
                    <div className={styles.col}>
                      {!isTemplate && paycalcItemTemplateId ? (
                        <SearchSelectField
                          label="Pay Classification"
                          id="earningType"
                          placeholder={
                            values?.earningType === 'GENERIC' ? 'Generic' : earningTypeMap.get(values.earningType)
                          }
                          disabled={true}
                          options={earningTypeOptions}
                          multiple={false}
                          allowSearch={false}
                          className={styles.payFrequency}
                        />
                      ) : (
                        <SearchSelectField
                          label="Pay Classification"
                          id="earningType"
                          disabled={isRipplingPTOItem}
                          options={filteredEarningTypeOptions}
                          multiple={false}
                          allowSearch={false}
                          className={styles.payFrequency}
                        />
                      )}
                    </div>
                  </div>
                )}
                <Field name="title">
                  {({ field, meta }) => (
                    <Input
                      disabled={isRipplingPTOItem}
                      name="title"
                      label="Item Title"
                      id="title"
                      input={field}
                      meta={meta}
                      onBlur={handleExternalTitleCopyOnBlur}
                    />
                  )}
                </Field>
                {isTemplate && (
                  <>
                    <Field name="externalTitle">
                      {({ field, meta }) => (
                        <Input
                          disabled={isRipplingPTOItem}
                          name="externalTitle"
                          label="External Title"
                          id="externalTitle"
                          input={field}
                          meta={meta}
                          onBlur={handleExternalTitleCopyOnBlur}
                          className={styles.externalTitle}
                          aria-describedby="external-title-behavior-description"
                        />
                      )}
                    </Field>
                    <div id="external-title-behavior-description" className={styles.externalTitleBehaviorDescription}>
                      <FeatherIcon name="AlertCircle" size={16} />
                      External Title will default to the provided Item Title if one is not provided
                    </div>
                  </>
                )}
                {isTemplate && (
                  <>
                    <div className={styles.formRow}>
                      <div className={styles.col}>
                        <Field name="company.name">
                          {({ field, meta }) => (
                            <Input
                              disabled={true}
                              name="company"
                              label="Company"
                              id="company"
                              input={field}
                              meta={meta}
                            />
                          )}
                        </Field>
                      </div>

                      <div className={styles.col}>
                        <SearchSelectField
                          label="Insurance Provider"
                          id="insuranceCompany"
                          options={insuranceProviders}
                          multiple={false}
                          allowSearch={false}
                        />
                      </div>
                    </div>
                    <fieldset>
                      <legend>Effective Start and End Date</legend>
                      <div className={styles.formRow}>
                        <div className={`${styles.col} ${styles.flexCol} ${styles.effectiveStartAndEnd}`}>
                          <Field name="effectiveStartDate">
                            {({ field, meta }) => {
                              if (field.value === '') {
                                setFieldValue('effectiveStartDate', null);
                              }
                              return (
                                <Input
                                  name="effectiveStartDate"
                                  label="From"
                                  id="effectiveStartDate"
                                  type="date"
                                  disabled={isRipplingPTOItem}
                                  input={field}
                                  meta={meta}
                                />
                              );
                            }}
                          </Field>
                          <Field name="effectiveEndDate">
                            {({ field, meta }) => {
                              if (field.value === '') {
                                setFieldValue('effectiveEndDate', null);
                              }
                              return (
                                <Input
                                  name="effectiveEndDate"
                                  label="To"
                                  id="effectiveEndDate"
                                  disabled={isRipplingPTOItem}
                                  type="date"
                                  input={field}
                                  meta={meta}
                                />
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                    </fieldset>
                  </>
                )}
                {isTemplate && (
                  <div className={styles.col}>
                    <SearchSelectField
                      label="Pay Classification"
                      id="earningType"
                      disabled={isRipplingPTOItem}
                      options={earningTypeOptions}
                      multiple={false}
                      placeholder={values?.earningType === 'GENERIC' && 'Generic'}
                      allowSearch={false}
                      className={styles.payFrequency}
                    />
                  </div>
                )}
                <div className={styles.formRow}>
                  <div className={styles.col}>
                    <SearchSelectField
                      label="Pay Frequency"
                      id="payPeriodLength"
                      disabled={isRipplingPTOItem}
                      options={itemPeriodLengthOptions}
                      multiple={false}
                      allowSearch={false}
                      className={styles.payFrequency}
                    />
                  </div>
                  {isTemplate || paycalcItemTemplateId ? (
                    <div className={`${styles.col} ${styles.itemColor}`}>
                      <FormikColorPickerInput id="color" disabled={!isTemplate || isRipplingPTOItem} />
                    </div>
                  ) : null}
                </div>
                <fieldset>
                  <legend>Duration:</legend>
                  {!isTemplate && (
                    <div className={styles.formRow} style={{ marginBottom: 20 }}>
                      <div className={styles.col28}>
                        <Field name="from">
                          {({ field, meta }) => (
                            <Input
                              name="from"
                              label="From"
                              id="from"
                              type="date"
                              disabled={isRipplingPTOItem}
                              input={field}
                              meta={meta}
                              min={minDate}
                              max={maxDate}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`${styles.col40} ${styles.flexCol} ${styles.duration}`}>
                        <Field name="durationInWeeks">
                          {({ field, meta }) => (
                            <Input
                              name="durationInWeeks"
                              label="Duration"
                              id="durationInWeeks"
                              disabled={isRipplingPTOItem}
                              type="number"
                              input={field}
                              meta={meta}
                              min={0}
                              step="1"
                            />
                          )}
                        </Field>
                        <span className={styles.durationUnit}>Weeks</span>
                        <Field name="durationInDays">
                          {({ field, meta }) => (
                            <Input
                              name="durationInDays"
                              label=""
                              id="durationInDays"
                              type="number"
                              disabled={isRipplingPTOItem}
                              input={field}
                              meta={meta}
                              min={0}
                              step="1"
                              className={styles.durationInDays}
                            />
                          )}
                        </Field>
                        <span className={styles.durationUnit2}>Days</span>
                      </div>
                      <div className={styles.col28}>
                        <Field name="to">
                          {({ field, meta }) => (
                            <Input
                              name="to"
                              label="To"
                              id="to"
                              disabled={isRipplingPTOItem}
                              type="date"
                              input={field}
                              meta={meta}
                              min={moment(minDate).isBefore(moment(from)) ? from : minDate}
                              max={maxDate}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  )}

                  <div className={styles.formRow}>
                    <div className={`${styles.col} ${styles.flexCol} ${styles.maxDuration}`}>
                      <Field name="maxDuration">
                        {({ field, meta }) => (
                          <Input
                            name="maxDuration"
                            label="Max Duration"
                            help="Used to calculate remaining amounts."
                            id="maxDuration"
                            type="number"
                            disabled={isRipplingPTOItem}
                            input={field}
                            meta={meta}
                            min={0}
                            step="0.01"
                            className={styles.numberInput}
                          />
                        )}
                      </Field>
                      <SearchSelectField
                        id="maxDurationUnit"
                        options={maxDurationUnitOptions}
                        multiple={false}
                        allowSearch={false}
                        disabled={isRipplingPTOItem}
                        placeholder="Unit"
                        label="Unit"
                        className={styles.maxDurationUnit}
                      />
                    </div>
                    <div className={styles.col}>
                      <Field name="waitingPeriod">
                        {({ field, meta }) => (
                          <Input
                            label="Waiting Period (days)"
                            id="waitingPeriod"
                            type="number"
                            input={field}
                            meta={meta}
                            disabled={isRipplingPTOItem}
                            min={0}
                            step="1"
                            className={styles.numberInput}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  {isTemplate && (
                    <div className={styles.formRow}>
                      <div className={styles.col}>
                        <SearchSelectField
                          label="Tracking method"
                          id="trackingMethod"
                          options={benefitTrackingOptions}
                          multiple={false}
                          allowSearch={false}
                        />
                      </div>
                    </div>
                  )}
                </fieldset>
                <fieldset>
                  <legend>Benefit:</legend>
                  <div className={styles.formRow}>
                    <Field name="usepayPercentage">
                      {({ meta, field }) => (
                        <RadioButtons
                          className={styles.radioButtonsContainer}
                          label=""
                          id="usepayPercentage"
                          options={[
                            { value: '0', label: 'Fixed Amount' },
                            { value: '1', label: 'Amount per Period Percentage' },
                          ]}
                          input={field}
                          meta={meta}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={styles.formRow}>
                    <div className={styles.col}>
                      {visibility.payPercentage(values) ? (
                        <Field name="payPercentage" validate={fieldRequired}>
                          {({ field, meta }) => (
                            <Input
                              label="Amount per Period Percentage"
                              id="payPercentage"
                              type="number"
                              input={field}
                              meta={meta}
                              min={0}
                              max={100}
                              step="0.01"
                            />
                          )}
                        </Field>
                      ) : (
                        <Field name="payPerPeriod" validate={fieldRequired}>
                          {({ field, meta }) => (
                            <Input label="Amount" id="payPerPeriod" type="text" input={field} meta={meta} min={0} />
                          )}
                        </Field>
                      )}
                    </div>
                    <div className={styles.col}>
                      <Field name="maxPayPerPeriod">
                        {({ field, meta }) => (
                          <Input
                            label="Max Amount"
                            id="maxPayPerPeriod"
                            type="text"
                            input={field}
                            meta={meta}
                            min={0}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  {visibility.rounding(values) ? (
                    <div className={styles.formRow}>
                      <Field name="roundingMethod">
                        {({ field, meta }) => (
                          <RadioButtons
                            className={styles.radioButtonsContainer}
                            label="Dollar Rounding"
                            id="roundingMethod"
                            options={roundingMethodOptions}
                            input={field}
                            meta={meta}
                          />
                        )}
                      </Field>
                    </div>
                  ) : null}
                  <div className={styles.formRow}>
                    <div>
                      <Field name="topsUpAmount">
                        {({ field, meta }) => (
                          <Checkbox
                            disabled={
                              (isRipplingPTOItem || ripplingTopUpSelected) && !flags?.enableRipplingTopUpFlexibility
                            }
                            text="Top Up Amount"
                            id="topsUpAmount"
                            input={field}
                            meta={meta}
                            fill={colors.primaryPeacock}
                          />
                        )}
                      </Field>
                      {isTemplate && (
                        <Field name="areBonusesIncluded">
                          {({ field, meta }) => (
                            <Checkbox
                              className={styles.checkboxContainer}
                              text="Includes Bonuses/Commissions"
                              id="areBonusesIncluded"
                              input={field}
                              meta={meta}
                              fill={colors.primaryPeacock}
                            />
                          )}
                        </Field>
                      )}
                    </div>
                    <ConditionalWrapper
                      condition={isTemplate}
                      wrapper={(children) => <div className={styles.col}>{children}</div>}
                    >
                      <Field name="isPaidByCompany">
                        {({ field, meta }) => (
                          <Checkbox
                            className={styles.checkboxContainer}
                            text="Is Paid By Company"
                            id="isPaidByCompany"
                            input={field}
                            meta={meta}
                            fill={colors.primaryPeacock}
                            disabled={!isTemplate}
                          />
                        )}
                      </Field>
                      <Field name="requiresApplication">
                        {({ field, meta }) => (
                          <Checkbox
                            className={styles.checkboxContainer}
                            text="Employee must file application"
                            id="requiresApplication"
                            input={field}
                            meta={meta}
                            fill={colors.primaryPeacock}
                            disabled={!isTemplate}
                          />
                        )}
                      </Field>
                    </ConditionalWrapper>
                  </div>
                  {totalAmount > 0 ? (
                    <div className={`${styles.row} ${styles.totalAmount}`}>
                      Total Computed Amount:{' '}
                      {roundAmount(totalAmount, values.roundingMethod, visibility.rounding(values))} (
                      {itemPeriodLengthMap.get(values.payPeriodLength)})
                    </div>
                  ) : null}
                </fieldset>
                <fieldset>
                  <legend>Advanced:</legend>
                  {visibility.proration(values) ? (
                    <div className={styles.formRow}>
                      <Field name="prorationMethod">
                        {({ field, meta }) => (
                          <RadioButtons
                            disabled={isRipplingPTOItem}
                            className={styles.radioButtonsContainer}
                            label="Proration Method"
                            id="prorationMethod"
                            options={prorationMethodOptions}
                            input={field}
                            meta={meta}
                          />
                        )}
                      </Field>
                    </div>
                  ) : null}
                  <div>
                    <label className={styles.checkboxContainerLabel}>
                      Intermittency - Limit how the item applies to Leave Days
                    </label>
                    <Field name="paysOnWorkedDays" key={1}>
                      {({ field, meta }) => (
                        <Checkbox
                          disabled={isRipplingPTOItem}
                          className={styles.checkboxContainer}
                          text="Pays on Worked Days"
                          id="paysOnWorkedDays"
                          input={field}
                          meta={meta}
                          fill={colors.primaryPeacock}
                        />
                      )}
                    </Field>
                    <Field name="paysOnLeaveDays" key={2}>
                      {({ field, meta }) => (
                        <Checkbox
                          className={styles.checkboxContainer}
                          text="Pays on Leave Days"
                          id="paysOnLeaveDays"
                          disabled={isRipplingPTOItem}
                          input={field}
                          meta={meta}
                          fill={colors.primaryPeacock}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={styles.formRow}>
                    <div className={styles.col}>
                      <Field name="order">
                        {({ field, meta }) => (
                          <Input
                            name="order"
                            label="Processing Order"
                            disabled={isRipplingPTOItem}
                            id="order"
                            type="number"
                            input={field}
                            meta={meta}
                            min={0}
                            step="1"
                            className={styles.numberInput}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={styles.formRow}>
                    <div className={styles.col}>
                      <Field name="approvalType">
                        {({ meta, field }) => (
                          <RadioButtons
                            disabled={paycalcItemTemplateId}
                            className={styles.radioButtonsContainer}
                            label="Approval Method"
                            id="approvalType"
                            options={approvalOptions}
                            input={field}
                            meta={meta}
                            onValueChange={(e) => {
                              if (e.target.value === '0' || e.target.value === '1') {
                                setFieldValue('documentClass', null);
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={cx(styles.formRow, styles.marginBottom)}>
                    <div className={styles.col}>
                      <SearchSelectField
                        disabled={paycalcItemTemplateId}
                        label="Document Type"
                        id={values?.template?.documentClass ? 'template.documentClass' : 'documentClass'}
                        options={documentClassOptions}
                        getOptions={(q) =>
                          documentClassOptions.filter((e) => e.name.toLowerCase().includes(q.toLowerCase()))
                        }
                        multiple={false}
                        autoComplete="on"
                        allowSearch={true}
                      />
                    </div>
                  </div>
                  <div className={styles.formRow}>
                    <Field name="hideWhenDenied">
                      {({ field, meta }) => (
                        <Checkbox
                          text="If this benefit is denied, hide benefit and status from employee’s plan"
                          id="hideWhenDenied"
                          label="Benefit Visibility"
                          input={field}
                          meta={meta}
                          disabled={paycalcItemTemplateId}
                          fill={colors.primaryPeacock}
                        />
                      )}
                    </Field>
                  </div>
                </fieldset>
                {!isTemplate && (
                  <Textarea disabled={isRipplingPTOItem} id="notes" label="Notes" className={styles.notesTextarea} />
                )}
                {isTemplate ? (
                  <>
                    <Textarea
                      disabled={isRipplingPTOItem}
                      id="description"
                      label="User Facing Description"
                      className={styles.notesTextarea}
                    />
                    <Textarea
                      disabled={isRipplingPTOItem}
                      id="help"
                      label="Internal Help Notes"
                      className={styles.notesTextarea}
                    />
                  </>
                ) : (
                  <>
                    <Textarea id="help" label="Internal Help Notes" className={styles.notesTextarea} disabled />
                    {isRipplingPTOItem && (
                      <>
                        <p className={styles.changesCheckboxText}>
                          Changes made will only affect Tilt and not Rippling
                        </p>
                        <Field name="acknowledgeRippling">
                          {({ field, meta }) => (
                            <Checkbox
                              className={styles.changesCheckboxContainer}
                              text="By checking this box, you confirm changes will only affect Tilt. To make changes in both places, adjustments must be made within Rippling."
                              id="acknowledgeRippling"
                              input={field}
                              meta={meta}
                              fill={colors.primaryPeacock}
                            />
                          )}
                        </Field>
                      </>
                    )}
                  </>
                )}
                <FormError error={submitError} />
              </div>
              <div className={styles.action}>
                <div>
                  {(isTemplate || (isEdit && !isRipplingPTOItem)) && onRemoveEvent && (
                    <Button
                      color={ButtonColor.CORAL}
                      onClick={() => onRemove(setSubmitting)}
                      type="button"
                      loading={isSubmitting && isRemove}
                      css={{
                        gap: '$8',
                      }}
                      disabled={isSubmitting || !canDeletePayCalcItemTemplates}
                    >
                      <FeatherIcon name="Trash" />
                      Remove
                    </Button>
                  )}
                </div>
                <div className={styles.rightActions}>
                  <div className={styles.button}>
                    <Button type="button" color={ButtonColor.OUTLINE} onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                  <div className={styles.button}>
                    <Button
                      type="submit"
                      disabled={
                        (isRipplingPTOItem && !values.acknowledgeRippling) ||
                        isSubmitting ||
                        (!canChangePayCalcItemTemplates && isEdit)
                      }
                      loading={isSubmitting && !isRemove}
                    >
                      {buttonLabel}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PayCalcItemModal;
